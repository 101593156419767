








































































import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import Notifier from "@/helpers/notifier";
import FinancialInstitution from "@/models/financialInstitution";
import _axios from "@/plugins/axios";
import agent from "@/helpers/agent";

@Component
export default class OrganizationsView extends Vue {
  private organizations: FinancialInstitution[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 5,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: FinancialInstitution) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "LegalName",
      required: true,
      label: "Legal Name",
      align: "left",
      field: (row: FinancialInstitution) => row.legalName,
      sortable: true,
    },
    {
      name: "IsActive",
      required: true,
      label: "Active?",
      align: "left",
      field: (row: FinancialInstitution) => row.isActive,
      format: (val: string) => `${val}`,
      sortable: false,
    },
    {
      name: "PhysicalAddress",
      required: true,
      label: "PhysicalAddress",
      align: "left",
      field: (row: FinancialInstitution) => row.physicalAddress,
      format: (val: string) => `${val}`,
      sortable: true,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];

  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.FinancialInstitutions.list(
      search,
      descending,
      page,
      size,
      sortBy,
    ).then(
      (rows) => {
        //populate table
        this.organizations = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "FormFinancialinstitution",
      query: { id },
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "Organizations.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
  deleteUser(id: string) {
    agent.FinancialInstitutions.delete(id).then(() => {
      const index = this.organizations.findIndex(
        (i) => i.financialInstitutionID == id
      );
      this.organizations.splice(index, 1);
      Notifier.showInfo(`Successfully deleted`);
    });
  }
}
